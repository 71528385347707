<template>
    <div class="wrapper">
        <h1>Тарифы</h1>
        <div class="tariff">
          <!-- <div class="tariff-item">
            <p class="tariff-item-head">Базовый</p>
            <p class="tariff-item-second-head">Что входит:</p>
            <ul class="tariff-list">
              <li class="tariff-list-item">До 100 диалогов</li>
              <li class="tariff-list-item">Персонализированные и групповые рассылки сообщений</li>
              <li class="tariff-list-item">Сервис отложенных напоминаний</li>
              <li class="tariff-list-item">Свое приложение</li>
            </ul>
            <div class="price">990 р/мес</div>
          </div> -->
          <div class="tariff-item">
            <p class="tariff-item-head">Оптимальный</p>
            <p class="tariff-item-second-head">Что входит:</p>
            <ul class="tariff-list">
              <li class="tariff-list-item">Не ограниченное количество диалогов</li>
              <li class="tariff-list-item">Персонализированные и групповые рассылки сообщений</li>
              <li class="tariff-list-item">Сервис отложенных напоминаний</li>
              <li class="tariff-list-item">Свое приложение</li>
            </ul>
            <div class="price">1490 р/мес</div>
          </div>
          <!-- <div class="tariff-item">
            <p class="tariff-item-head">Максимальный</p>
            <div class="price">СКОРО</div>
          </div> -->
            <!-- <table>
                <caption>Тарифы на предоставление доступа к сервису Limza.CHAT</caption>
                    <thead>
                      <tr>
                        <th scope="col">Количество активных чатов*</th>
                        <th scope="col">Стоимость за 1 месяц без НДС, руб.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="grey">
                        <th scope="row">До 100</th>
                        <td>990</td>
                      </tr>
                      <tr>
                        <th scope="row">От 101 до 200</th>
                        <td>1881</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">От 201 до 300</th>
                        <td>2673</td>
                      </tr>
                        <tr>
                        <th scope="row">От 301 до 400</th>
                        <td>3366</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">От 401 до 500</th>
                        <td>3960</td>
                      </tr>
                      <tr>
                        <th scope="row">От 501 до 600</th>
                        <td>4455</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">От 601 до 700</th>
                        <td>4851</td>
                      </tr>
                      <tr>
                        <th scope="row">От 701 до 800</th>
                        <td>5148</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">От 801 до 900</th>
                        <td>5346</td>
                      </tr>
                      <tr>
                        <th scope="row">От 901 до 1000</th>
                        <td>5940</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">Свыше 1000</th>
                        <td>5,94 за 1 чат, но не менее 5940</td>
                      </tr>
                    </tbody>
                  </table> -->

                  <!-- <div class="note">*активным считается чат, в котором в течение отчетного периода (1 мес) был передан хотя бы 1 символ.</div> -->
        </div>
    </div>
</template>
<script>
export default {
  name: 'TariffPage'
}
</script>
<style scoped>
h1{
  text-align: center;
  font-size: 22px;
  line-height: 2
}
.wrapper{
  height: calc(100vh - 150px);
}
.tariff{
  display: flex;
  justify-content: space-evenly;
}
.tariff-item{
  width: 30%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
}
.tariff-item-head{
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 2;
}
.tariff-item-second-head{
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
}
.tariff-list{
  list-style-type: circle;
  line-height: 1.5;
  font-size: 16px;
}
.tariff-list-item{
  font-size: 14px;
}
a{
  text-decoration: none;
}
.price{
  text-align: center;
  font-size: 40px;
  margin: 15px auto 0px auto;
}

  .note{
    margin: auto;
    width: 50vw;
    min-width: 500px;
    margin-bottom: 50px;
  }
  .grey{
    background-color: #d3d3d384;
  }
@media (max-width: 767px){
  .wrapper{
    height: fit-content;
  }
    .tariff{
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 15px;
    }
    .tariff-item{
      width: 80%;
    }
}
</style>
