<template>
  <div class="ring">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'my-ring',
  props: {
    background: {
      type: String
    },
    filter: {
      type: String
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    top: {
      type: String
    },
    left: {
      type: String
    }
  }
}
</script>

<style>
.ring{
  position: absolute;
  border-radius: 50%;
  /* filter: blur(200px);
  width: 555px;
  height: 555px;
  background: #9AD2B22B;
  left: -254px;
  top: -110px; */
}

</style>
