import { createRouter, createWebHistory } from 'vue-router'
import LandigPage from '@/views/LandingPage.vue'
import OffertaMay23 from '@/views/OffertaMay23.vue'
import Tariff from '@/views/Tariff.vue'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandigPage
  },
  {
    path: '/Offerta',
    name: 'Offerta',
    component: OffertaMay23
  },
  {
    path: '/Tariff',
    name: 'Tariff',
    component: Tariff
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
